<template>
    <v-container fluid>
        <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Approval Status Report</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn color="accent" small to="/new-user">
            <v-icon left dark>mdi-plus</v-icon>New User
          </v-btn> -->
        </v-toolbar>
        
        <v-card-text>
            <v-row>
                <v-btn @click.native="dialog = true" class="primary my-3">Select Documents</v-btn>

                <v-dialog v-model="dialog" max-width="850px">
                    <v-card>
                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title>Select Documents</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click.native="dialog = false">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-col cols="12">
                            <v-row dense>
                                <v-col cols="4" v-for="doc in documents" v-bind:key="doc.id">
                                <v-checkbox v-model="selectedDocuments" :value="doc.ObjectID" :label="`${doc.DocumentName}`"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-btn
                                class="primary"
                                @click="dialog = false ; submitSelectedDocuments() "
                            >
                            Submit</v-btn>
                        </v-col>
                    </v-card-text>
                    </v-card>
                </v-dialog>
                
            </v-row>
            <v-row>
                <v-tabs v-model="selectedTab" background-color="accent" color="white">
                    <v-tab to="/approval_status/pending">Pending</v-tab>
                    <v-tab to="/approval_status/approved">Approved</v-tab>
                    <v-tab to="/approval_status/generated">Generated</v-tab>
                    <v-tab to="/approval_status/authorizer">Generated by Authorizer</v-tab>
                    <v-tab to="/approval_status/rejected">Rejected</v-tab>
                    <v-tab to="/approval_status/canceled">Cancelled</v-tab>
                </v-tabs>
            </v-row>
            <v-row>
                <router-view :selectedDocuments="selectedDocuments"/>
            </v-row>
        </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data(){
            return {
                documents: [],
                dialog: false,
                selectedDocuments: [],
                selectedTab: "/approval_status/pending",
                activeTab: ""
            };
        },
        methods: {
            getApprovals() {
                this.$store
                    .dispatch("get", `/get-pending-status`)
                    .then((res) => {
                        //console.log(res.ResponseData, 'approvals');
                        //console.log(this.selectedDocuments, 'selectedDocuments')
                        this.$router.push({ path: this.selectedTab, query: { data: res.ResponseData } });                        
                    })
                    .catch((err) => {
                        this.$refs.snackbar.show(err, "red");
                    });
            },
            getDocs() {
                const self = this;
                this.$store
                    .dispatch("get", `/doc_model`)
                    .then((res) => {
                        self.documents = res;
                        //console.log(res, 'documents');
                    })
                    .catch((err) => {
                        this.$refs.snackbar.show(err, "red");
                    });
            },

            getSelectedDocuments()
            {
                if(this.selectedDocuments.length > 0)
                {
                    //console.log(this.selectedDocuments);
                    this.getApprovals(this.selectedDocuments)
                        .then(() => {
                            this.$refs.snackbar.show("Selected documents submitted successfully", "green");
                        })
                        .catch((err) => {
                            this.$refs.snackbar.show(err, "red");
                        });                    
                }else{
                    this.$refs.snackbar.show("Please select at least one document", "red");
                }
            },

            submitSelectedDocuments()
            {
                this.getSelectedDocuments();
            }            
        },
        created() {
            this.getDocs();
        },
    }
</script>

<style lang="scss" scoped>

</style>